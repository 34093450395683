<template>
    <div class="Message xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>收货地址</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;<span>收货地址</span></i>
                        <div class="el-icon-plus" @click="goAddAll">新增收货地址</div>
                    </div>
                    <!--    内容    -->
                    <div class="center-content">
                        
                    <el-table :data="MessageList">
                        <el-table-column prop="addressee" label="姓名"> </el-table-column>
                        <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>
                        <el-table-column prop="regionName" label="区域" align="center"> </el-table-column>
                        <el-table-column prop="address" label="地址" align="center"> </el-table-column>
                        <el-table-column prop="isDefault" label="是否为默认地址" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.isDefault ? '是' : '否' }}
                            </template>
                            
                        </el-table-column>
                        <el-table-column fixed="right" label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" type="success" plain @click="gorecord(scope.row)">编辑 </el-button>
                                <el-button size="mini" type="danger" plain @click="gordelete(scope.row)">删除 </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    </div>
                    <!--    详情    -->
                </div>
            </div>
        </div>
        <el-dialog
            title="收货管理"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="地区" prop="regionId">
                    <el-cascader :placeholder="ruleForm.regionName ? ruleForm.regionName : '请选择所在区域'"
                            v-model="ruleForm.regionId"
                            :options="RegionData"
                            size="medium"
                            :props="{  emitPath: false, multiple: false, checkStrictly: true,lazy: true,lazyLoad:LasyLoadRegion, value:'id',label:'regionName',children:'childrens' }"
                            style="margin-right: 10px;width: 100%;"
                            @change="gochange"
                            ref="refSubCat"
                            >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="详细住址" prop="address">
                    <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item label="收货人" prop="addressee">
                    <el-input type="addressee" v-model="ruleForm.addressee"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input type="phone" v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="是否设为默认" prop="isDefault">
                    <el-switch v-model="ruleForm.isDefault"></el-switch>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <ft-com></ft-com>
    </div>
</template>

<script>
import { shoppingMall } from '../../components/shoppingMall/shoppingMall';
// import { shoppingMall } from '../../components/shoppingMall/shoppingMall';
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
var pageIndex = 1;
var keywordVal = ''
export default {
    name: "Message",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var shoppingMalltem = new shoppingMall(this.TokenClient, this.Services.shoppingMall);
        var region = new shoppingMall(this.TokenClient, this.Services.Authorization);
        return {
            labelPosition: 'right',
            MessageList: [],
            MessageDetail: {},
            total: 0,
            currentPage: 1, //初始页
            keyword: '',
            PageIndex: 1,
            PageTotal: 1,
            MessageTotal: 0,
            rloading: [],//loading遮罩
            shoppingMallMain: shoppingMalltem,
            Region: region,
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
            dialogVisible:false,
            ruleForm:{
                address: '',
                addressee: '',
                regionId: null,
                regionName:'',
                region:'',
                phone: '',
                fullAddress: '',
                isDefault: false,
                regionCode:''
            },
            RegionData:[],
            rules:{
                    addressee:[
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    phone:[
                        { required: true, message: '请输入联系方式', trigger: 'blur' },
                        { pattern:/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                    ],
                },
        };
    },
    mounted() {
        this.AllMessages();
        this.InitialRegion()
    },
    methods: {
        gochange(){
            this.ruleForm.regionName = this.$refs["refSubCat"].getCheckedNodes()[0].data.fullName
            this.ruleForm.regionCode = this.$refs["refSubCat"].getCheckedNodes()[0].data.regionCode
        },
        gorecord(item){
            console.log(item)
            var _this =this
            _this.dialogVisible = true
            _this.ruleForm = item
        },
        gordelete(item){
            var _this = this
            _this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    _this.shoppingMallMain.deleteReceivingAddress(item.id,function (data) {
                            _this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            _this.AllMessages()
                    },
                    )
            
                }).catch(() => {
                    
                });
            
        },
        resetForm(){
            var _this = this
            _this.ruleForm={
                    address: '',
                    addressee: '',
                    regionId: null,
                    regionName:'',
                    region:'',
                    phone: '',
                    fullAddress: '',
                    isDefault: false,
                    regionCode:''
                }
                _this.dialogVisible = false
        },
        submitForm(formName){
            var _this = this
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.ruleForm.fullAddress =  this.ruleForm.regionName + this.ruleForm.address
               let  params = _this.ruleForm
                if(_this.ruleForm.id) {
                    _this.shoppingMallMain.putReceivingAddress(params,function (data) {
                        _this.ruleForm={
                            address: '',
                            addressee: '',
                            regionId: null,
                            regionName:'',
                            region:'',
                            phone: '',
                            fullAddress: '',
                            isDefault: false,
                            regionCode:''
                        },
                        _this.dialogVisible = false
                        _this.AllMessages()
                        
                    },
                        function (err) {
                            _this.MessageList = []
                            console.log(err);
                        }
                    )
                } else {
                    _this.shoppingMallMain.addReceivingAddress(params,function (data) {
                        _this.ruleForm={
                            address: '',
                            addressee: '',
                            regionId: null,
                            regionName:'',
                            region:'',
                            phone: '',
                            fullAddress: '',
                            isDefault: false,
                            regionCode:''
                        },
                        _this.dialogVisible = false
                        _this.AllMessages()
                        
                    },
                        function (err) {
                            _this.MessageList = []
                            console.log(err);
                        }
                    )
                }
                }
           
            })
        },
        InitialRegion() {
            var _this = this;
            _this.Region.Regions(null,
                function (data) { _this.RegionData = data.data; },
                function (err) { console.log(err); });
        },
        LasyLoadRegion(node, resolve) {
                var _this = this;
                _this.Region.Regions(node.value,
                    function (data) {
                        for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
                        }
                        resolve(data.data);
                    },
                    function (err) {
                        resolve([]);
                    });
        },
        goAddAll(){
            var _this = this
            _this.ruleForm={
                    address: '',
                    addressee: '',
                    regionId: null,
                    regionName:'',
                    region:'',
                    phone: '',
                    fullAddress: '',
                    isDefault: false,
                    regionCode:''
                },
            _this.dialogVisible = true
            _this.InitialRegion()
        },
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.filePath;
            this.Image.dialogVisible = true;
        },
        //返回列表页
        goBack() {
            $('.center-content').show();
            $('.personal-right-tit').show();
            $('.msg-detail').hide();

        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum;
            pageIndex = pageNum;
            this.AllMessages();
        },
        toDetail(i) {
            var _this = this;
            $('.center-content').hide();
            $('.personal-right-tit').hide();
            $('.msg-detail').show();
            _this.Result.MessageDetails(_this.MessageList[i].id, function (data) {
                _this.MessageDetail = data.data;
            }, function (error) {
                console.log(error);
            })
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.AllMessages();
        },
        AllMessages() {
            var _this = this;
            _this.shoppingMallMain.getReceivingAddress(function (data) {
                if (data.data) {
                    _this.MessageList = data.data
                }
            },
                function (err) {
                    _this.MessageList = []
                    console.log(err);
                }
            )
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

#page {
    text-align: center;
    padding: 100px 0 30px;
}

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}
.center-content {
    margin: 10px;
}
.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    min-height: 740px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right-tit .el-icon-delete {
    float: right;
    font-size: 18px;
}

.msg-list {
    padding: 0 20px;
}

.msg-list .msg-item {
    padding: 8px 0;
    border-bottom: 1px dashed #e5e5e5;
    line-height: 40px;
    height: 40px;
}

.msg-list .msg-item h4 {
    color: #999999;
    font-weight: normal;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.msg-list .msg-item.noread h4 {
    color: #333333;
}

.msg-list .msg-item p {
    color: #999999;
}

.msg-detail {
    padding: 20px;
    display: none;
}

.msg-detail .mess-tit {
    text-align: center;
}

.msg-detail .mess-info p {
    text-indent: 28px;
}
.mess-info  .item-img {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
}
.item-img img {
    width: 118px;
    height: 118px;
}
</style>
